var swiper = new Swiper('.swiper-container', {
  slidesPerView: 2,
  spaceBetween: 0,
  centeredSlides: true,
  grabCursor: true,
  breakpoints: {
    
    768: {
      slidesPerView: 4,
      spaceBetween: 40,
   
  },
  1024: {
    slidesPerView: 4,
    spaceBetween: 40,
 
}
}
});
AOS.init();
